<template>
  <div :class="[$style.videoBoxWrap, ...ContentStyle]">
    <video-box
      defaultBox
      class="camera-video-box"
      :class="[...BoxStyle(viewIndex)]"
      v-for="(view, viewIndex) in modelList"
      :key="viewIndex"
    >
      <draggable
        tag="div"
        :style="{ height: '100%' }"
        v-model="view.cameras"
        v-bind="ViewOptions"
        :move="() => false"
      >
        <video-view
          v-for="(item, index) in view.cameras"
          closed
          :data="item"
          :key="index"
          :accessToken="item.token"
          :url="item.url"
          @close="handleVideoClosed"
        ></video-view>
      </draggable>
    </video-box>
  </div>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import VideoBox from '../show/video-box.vue';
import VideoView from '@/components/video-view';
import {
  emitter,
  CAMETA_SCREEN_MODEL,
  MODEL,
  CAMETA_EDIT,
  CAMETA_EDIT_MODEL_NAME,
} from './final';
import {
  saveCameraViewMode,
  saveViewPublic,
  getCameraViewModelDetails,
} from '@/services/monitor/camera-view-list';

@Component({
  components: {
    VideoBox,
    VideoView,
    Draggable,
  },
})
export default class EditorView extends Vue {
  async mounted() {
    await this.fetchData();

    emitter.on(CAMETA_SCREEN_MODEL, this.handleScreenModel);
    emitter.on(CAMETA_EDIT_MODEL_NAME, this.handleEditModelName);
  }

  editArray = [];
  editObject = {};
  async fetchData() {
    if (!this.$route.query.i) {
      this.initModeList();
      return false;
    }
    const ID = this.$route.query.i;
    const list = await getCameraViewModelDetails(ID);
    const obj = list ? list : {};

    this.editObject = obj;
    const index = this.findModel(obj);
    const result = {
      index: index,
      list: obj.model,
      modelName: obj.modelName,
    };
    this.currentCameraModel = index;
    this.handleEdit(result);
    emitter.emit(CAMETA_EDIT, result);
  }

  findModel(obj) {
    let index = -1;
    Object.keys(this.MODEL).forEach(item => {
      const label = this.MODEL[+item].label;
      if (obj.modelLabel === label) {
        index = +item;
      }
    });
    return index;
  }

  modelName = '';
  handleEditModelName(name) {
    this.modelName = name;
  }

  MODEL = MODEL;
  model = MODEL[0];
  currentCameraModel = 0;
  handleScreenModel(index) {
    this.currentCameraModel = index;
    this.model = MODEL[index];
    this.initModeList();
  }
  async handleSave(type) {
    let result = 0;
    this.modelList.forEach(item => {
      if (item.cameras.length > 0) {
        result++;
      }
    });
    if (result === 0) {
      this.$message.warning('请至少添加1个摄像头');
      return false;
    } else {
      const array = [];
      this.modelList.forEach((item, itemIndex) => {
        if (item.cameras.length > 0) {
          array.push({
            number: itemIndex,
            cameraId: item.cameras[0].cameraId,
          });
        }
      });
      let result = {
        model: array,
        modelName: this.modelName || this.MODEL[this.currentCameraModel].name,
        modelLabel: this.MODEL[this.currentCameraModel].label,
      };
      /**
       * @name 是否为编辑模式
       */
      if (this.$route.query.i) {
        result = {
          modelNumber: Number(this.$route.query.i),
          model: array,
          modelName: this.modelName || this.MODEL[this.currentCameraModel].name,
          modelLabel: this.MODEL[this.currentCameraModel].label,
        };
      }

      const data = await saveCameraViewMode(result);
      let msg = '保存成功!';
      if (type === 'SaveAndShare') {
        await saveViewPublic({
          modelNumber: data,
        });
        msg = '共享成功!';
      }
      this.$message.success({
        content: msg,
        duration: 0.2,
        onClose: () => {
          this.modelList = [];
          this.$router.push('/iot/cameraView');
        },
      });
    }
  }

  get ContentStyle() {
    return [
      this.model.label === 'MODEL_FOUR' ? this.$style.fourBox : '',
      this.model.label === 'MODEL_NINE' ||
      this.model.label === 'MODEL_LEFT' ||
      this.model.label === 'MODEL_RIGHT'
        ? this.$style.nineBox
        : '',
      this.model.label === 'MODEL_TWELVE' || this.model.label === 'MODEL_MAIN'
        ? this.$style.twelveBox
        : '',
    ];
  }
  BoxStyle(index) {
    return [
      this.model.label === 'MODEL_MAIN' && index === 0
        ? this.$style.boxOnePointer
        : '',
      this.model.label === 'MODEL_LEFT' && index === 0
        ? this.$style.boxOneLeftMain
        : '',
      this.model.label === 'MODEL_RIGHT' && index === 0
        ? this.$style.boxOneRightMain
        : '',
    ];
  }

  modelList = [];
  initModeList() {
    const array = [];
    for (let i = 0; i < this.model.length; i++) {
      array.push({
        cameras: [],
      });
    }
    this.modelList = array;
  }
  @Watch('modelList', { deep: true })
  onChangeModelList(value) {
    this.$emit('list-change', value);
  }
  handleVideoClosed(data) {
    const cameraId = data.cameraId;
    let resultIndex = -1;
    try {
      this.modelList.forEach((item, itemIndex) => {
        item.cameras.forEach(camera => {
          if (camera.cameraId === cameraId) {
            resultIndex = itemIndex;
          }
        });
      });
      this.modelList[resultIndex].cameras = [];
    } catch {
      return false;
    }

    this.$emit('delete', cameraId);
  }

  handleEdit(data) {
    const index = data.index;
    this.currentCameraModel = index;
    this.model = MODEL[index];
    this.modelName = data.modelName;
    this.initModeList();

    /**
     * TODO 测试 添加了 url, token
     */
    data.list.forEach(item => {
      this.modelList[item.number].cameras = [
        {
          ...item,
          // url: 'ezopen://UYJSRZ@open.ys7.com/G37831237/1.hd.live',
          // token:
          //   'at.99tcu5xv897reev15meloq2f5d8wzt35-8z5ctodr99-0ylov1z-z23peodzd',
          hasDrag: true,
        },
      ];
    });
  }

  get ViewOptions() {
    return {
      animation: 0,
      group: 'viaCamera',
      sort: false,
      disabled: false,
      ghostClass: 'ghost',
    };
  }
}
</script>
<style lang="less" module>
.videoBoxWrap {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 0.2rem;
  column-gap: 0.2rem;
  &.fourBox {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  &.nineBox {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  &.twelveBox {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  // 焦点模式
  .boxOnePointer {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  // 主次模式-左大
  .boxOneLeftMain {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  // 主次模式-右大
  .boxOneRightMain {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
  }
}
</style>
