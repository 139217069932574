<template>
  <div :class="$style.wrap">
    <div
      :class="[$style.openMode, currentIndex === 0 ? $style.actived : '']"
      @click="handleClick(0)"
    >
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[
        $style.openMode,
        $style.fourBox,
        currentIndex === 1 ? $style.actived : '',
      ]"
      @click="handleClick(1)"
    >
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[
        $style.openMode,
        $style.nineBox,
        currentIndex === 2 ? $style.actived : '',
      ]"
      @click="handleClick(2)"
    >
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[
        $style.openMode,
        $style.twelveBox,
        currentIndex === 3 ? $style.actived : '',
      ]"
      @click="handleClick(3)"
    >
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[
        $style.openMode,
        $style.twelveBox,
        currentIndex === 4 ? $style.actived : '',
      ]"
      @click="handleClick(4)"
    >
      <div :class="[$style.openModeBox, $style.boxOnePointer]"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[
        $style.openMode,
        $style.nineBox,
        currentIndex === 5 ? $style.actived : '',
      ]"
      @click="handleClick(5)"
    >
      <div :class="[$style.openModeBox, $style.boxOneLeftMain]"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
    <div
      :class="[$style.openMode, currentIndex === 6 ? $style.actived : '']"
      @click="handleClick(6)"
    >
      <div :class="[$style.openModeBox, $style.boxOneRightMain]"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
      <div :class="$style.openModeBox"></div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { emitter, CAMETA_SCREEN_MODEL, CAMETA_EDIT } from './final';

@Component()
export default class ScreenModel extends Vue {
  mounted() {
    emitter.on(CAMETA_EDIT, this.handleScreenModel);
  }
  handleScreenModel(data) {
    this.currentIndex = data.index;
  }

  currentIndex = 0;
  handleClick(index) {
    this.currentIndex = index;
    emitter.emit(CAMETA_SCREEN_MODEL, index);
  }
}
</script>
<style lang="less" module>
@fontColor: rgba(5, 211, 255, 1);
.wrap {
  display: inherit;
}
.openMode {
  width: 0.76rem;
  height: 0.46rem;
  margin-right: 0.12rem;
  display: grid;
  row-gap: 0.02rem;
  column-gap: 0.02rem;

  &.actived {
    .openModeBox {
      background-color: @fontColor;
    }
  }

  &.fourBox {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  &.nineBox {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  &.twelveBox {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .openModeBox {
    background-color: rgba(153, 153, 153, 1);
  }
  // 焦点模式
  .boxOnePointer {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  // 主次模式-左大
  .boxOneLeftMain {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
  }
  // 主次模式-右大
  .boxOneRightMain {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 4;
  }
}
</style>
