<template>
  <div :class="$style.header">
    <div :class="$style.screenOpen">
      <span :class="$style.openLabel">分屏：</span>
      <screen-model />
    </div>
    <span :class="$style.title">
      {{ modelName }}
      <i
        class="TC tc-icon-xiugai1"
        :class="$style.icon"
        @click.stop="handleEditScreenName"
      ></i>
    </span>
    <div :class="$style.operation">
      <router-link to="/iot/cameraView" :class="$style.btn">取消</router-link>
      <span :class="[$style.btn, $style.btnPrimary]" @click="() => handleSave()"
        >仅保存</span
      >
      <span
        :class="[$style.btn, $style.btnPrimary]"
        @click="() => handleSaveAndShare()"
        >保存并共享</span
      >
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import createModal from '@/views/iot/screen/components/message/modal.js';
import EditTitle from '../show/edit-title.vue';
import ScreenModel from './screen-model.vue';
import {
  emitter,
  CAMETA_SCREEN_MODEL,
  MODEL,
  CAMETA_EDIT,
  CAMETA_EDIT_MODEL_NAME,
} from './final';

@Component({
  components: {
    ScreenModel,
  },
})
export default class HeaderView extends Vue {
  mounted() {
    emitter.on(CAMETA_SCREEN_MODEL, this.handleScreenModel);
    emitter.on(CAMETA_EDIT, this.handleEdit);
  }

  handleEdit(data) {
    this.modelName = data.modelName;
    emitter.emit(CAMETA_EDIT_MODEL_NAME, this.modelName);
  }

  modelName = MODEL[0].name;
  handleScreenModel(index) {
    this.modelName = MODEL[index].name;
    emitter.emit(CAMETA_EDIT_MODEL_NAME, this.modelName);
  }

  @Debounce(500)
  handleSave() {
    this.$emit('save', 'Save');
  }
  @Debounce(500)
  handleSaveAndShare() {
    this.$emit('save', 'SaveAndShare');
  }

  handleEditScreenName() {
    createModal(
      () => (
        <EditTitle
          limit={{ length: 24, label: '标题最长不超过12汉字/24英文字符长度' }}
          title={this.modelName}
          ref={'EditRef'}
          change={name => this.changeTitle(name)}
        />
      ),
      {
        title: '编辑模式名称',
        className: this.$style.warningModal,
        okTxt: '确定',
        ok: () => {
          this.$refs.EditRef && this.$refs.EditRef.handleOk();
        },
      },
    );
  }
  changeTitle(name) {
    this.modelName = name;
    if (this.modelName) {
      emitter.emit(CAMETA_EDIT_MODEL_NAME, this.modelName);
    }
  }
}
</script>
<style lang="less" module>
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
@lineColor: rgba(13, 200, 254, 1);
@lightLineColor: rgba(13, 200, 254, 0.5);
@boxshadow: 0px 0px 18px 2px rgba(12, 181, 232, 0.4) inset;
.header {
  position: relative;
  .screenOpen {
    position: absolute;
    display: flex;
    align-items: center;
    .openLabel {
      line-height: 0.79rem;
      padding-left: 0.2rem;
      font-size: 0.16rem;
      font-weight: 900;
    }
  }
  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0.4rem;
    font-size: 0.36rem;
    color: @fontColor;
    text-align: center;
    &:hover {
      .icon {
        display: inline-block;
      }
    }
    .icon {
      display: none;
    }
  }
  .operation {
    position: absolute;
    right: 0.2rem;
    top: 50%;
    transform: translateY(-50%);

    .btn {
      display: inline-block;
      cursor: pointer;
      // width: 0.78rem;
      // height: 0.3rem;
      padding: 0.03rem 0.16rem;
      // line-height: 0.3rem;
      text-align: center;
      font-size: 0.16rem;
      color: rgba(153, 153, 153, 1);
      border: 0.01rem solid rgba(204, 204, 204, 1);
      &.btnPrimary {
        border-color: @fontColor;
        color: #fff;
        background-color: @fontColor;
      }
    }
    .btn + .btn {
      margin-left: 0.2rem;
    }
  }
}
.warningModal {
  :global(.ant-modal-body) {
    padding-top: 0.15rem;
    margin-bottom: 0.15rem;
  }
  :global(.ant-modal-content) {
    background-color: @bgColor;
    border-radius: unset;
  }
  :global(.ant-modal-header) {
    color: @fontColor;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 98%
    );
    border: 1px solid @lightLineColor;
    box-shadow: @boxshadow;
    padding: 0.1rem 0.14rem;
    font-size: 0.14rem;
    border-radius: unset;
  }
  :global(.ant-modal-title) {
    color: @fontColor;
    font-weight: 200;
    font-size: 0.14rem;
    text-align: left;
  }
  :global(.ant-modal-close-x) {
    width: 0.4rem;
    height: 0.34rem;
    line-height: 0.34rem;
    color: @fontColor;
    font-size: 0.14rem;
    padding: 0.05rem 0.14rem;
  }
  :global(.ant-modal-close-x .anticon) {
    vertical-align: unset;
  }
}
</style>
