<template>
  <div :class="$style.modelWrap">
    <div :class="$style.modelBox">
      <edit-header :class="$style.header" @save="handleSave" />
      <edit-main :class="$style.main" ref="refMain" />
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import EditHeader from './header-view.vue';
import EditMain from './main-view.vue';

@Component({
  components: {
    EditHeader,
    EditMain,
  },
})
export default class VideoModel extends Vue {
  async handleSave(type) {
    try {
      this.$refs?.refMain.$refs.refEditor.handleSave(type);
    } catch (error) {
      return false;
    }
  }
}
</script>
<style lang="less" module>
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
.modelWrap {
  background: @bgColor;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0.14rem;

  .modelBox {
    border: 0.01rem solid @fontColor;
  }

  .header {
    height: 0.79rem;
    border-bottom: 0.01rem solid @lineColor5;
  }

  .main {
    height: calc(100vh - 1.1rem);
  }
}
</style>
