<template>
  <video-box :class="$style.videoSearch">
    <div :class="$style.formWrap">
      <div :class="$style.inputWrap">
        <span class="TC tc-icon-sousuo" :class="$style.formIcon"></span>
        <input
          type="text"
          placeholder="项目名称"
          v-model="query"
          :class="$style.formInput"
          @keyup.enter.stop="() => handleQuery()"
        />
      </div>
      <span :class="$style.formSearch" @click.stop="() => handleQuery()"
        >搜索</span
      >
    </div>
    <div
      :class="$style.searchList"
      @scroll.passive="$event => wrapScroll($event)"
    >
      <div ref="refList">
        <div
          :class="$style.searchItem"
          v-for="item in projects"
          :key="item.projectName"
        >
          <div :class="$style.searchTitle">
            <span class="TC tc-icon-xiangxia1" :class="$style.focusIcon"></span
            >{{ item.projectName }}
          </div>
          <draggable
            tag="div"
            v-model="item.cameras"
            v-bind="ItemOptions"
            :move="handleMove"
          >
            <div
              :class="[
                $style.searchVideoItem,
                camera.hasDrag ? $style.notMove : '',
              ]"
              v-for="camera in item.cameras"
              :key="camera.cameraId"
            >
              <span
                :class="[
                  $style.searchIcon,
                  camera.hasDrag ? $style.disabled : '',
                ]"
                class="TC tc-icon-yidong1"
              ></span>
              <span :class="$style.name">{{ camera.cameraName }}</span>
              <span
                :class="[$style.monitorIcon, iconStatus(camera)]"
                class="TC tc-icon-shexiangtou"
              ></span>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </video-box>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'lodash-decorators';
import VideoBox from '../show/video-box.vue';
import { getCameraViewList } from '@/services/monitor/camera-view-list';
import { emitter, CAMETA_SCREEN_MODEL } from './final';
import { getVisibleHeight, getScrollTop } from '@/utils/element';

@Component({
  components: {
    VideoBox,
  },
})
export default class ListView extends Vue {
  mounted() {
    this.fetchData();

    emitter.on(CAMETA_SCREEN_MODEL, this.handleScreenModel);
  }

  @Debounce(300)
  wrapScroll(e) {
    let rect = this.$refs.refList.getBoundingClientRect();
    let clientHeight = getVisibleHeight(e.target);
    let scrollTop = getScrollTop(e.target);
    let distance = rect.height - clientHeight - scrollTop;
    if (distance < 150) {
      if (this.totalPage < this.current) {
        this.current++;
        this.fetchData();
      }
    }
  }

  handleDeleteModel(cameraId) {
    this.projects.forEach(item => {
      item.cameras.forEach(camera => {
        if (camera.cameraId === cameraId) {
          camera.hasDrag = false;
        }
      });
    });
  }

  handleScreenModel() {
    this.projects.forEach(item => {
      item.cameras.forEach(camera => {
        camera.hasDrag = false;
      });
    });
  }

  modelList = [];
  cameraList = [];
  handleChangeModelList(list) {
    this.modelList = list;

    const array = [];
    this.modelList.forEach(item => {
      item.cameras.forEach(camera => {
        array.push(camera);
      });
    });
    this.cameraList = array;

    this.checkProjects();
  }

  checkProjects() {
    this.projects.forEach(item => {
      item.cameras.forEach(camera => {
        const index = this.cameraList.findIndex(
          v => v.cameraId === camera.cameraId,
        );
        if (index !== -1) {
          camera.hasDrag = true;
        }
      });
    });
  }

  query = '';
  @Debounce(500)
  handleQuery() {
    this.projects = [];
    this.fetchData();
  }
  current = 1;
  size = 30;
  totalPage = 0;
  projects = [];
  async fetchData() {
    const { records, pages } = await getCameraViewList({
      projectName: this.query,
      current: this.current,
      size: this.size,
    });
    this.totalPage = pages;
    let result = this.formatData(records);
    this.projects = this.projects.concat(result);

    if (this.cameraList.length) {
      this.checkProjects();
    }
  }
  /** @name 格式化数据 */
  formatData(list) {
    return list.map(item => {
      return {
        ...item,
        cameras: item.cameras.map(v => {
          return {
            ...v,
            hasDrag: false,
            // url: 'ezopen://WSDRAB@open.ys7.com/F30203581/1.hd.live',
            // token:
            //   'at.6wz0zt03d4tifanxdmh44qgn7o47q41t-968n2v5ib7-00xwbtq-foadirsso',
          };
        }),
      };
    });
  }

  get ItemOptions() {
    return {
      animation: 0,
      group: {
        name: 'viaCamera',
        pull: 'clone',
      },
      sort: false,
      ghostClass: 'ghost',
    };
  }
  handleMove(event) {
    /** @name 是否可以标记已拖拽的监控 */
    const cameraId = event.draggedContext.element.cameraId;
    const result = this.cameraList.findIndex(
      item => item.cameraId === cameraId,
    );
    // 不允许拖拽
    if (result > -1) {
      return false;
    } else {
      return true;
    }
  }

  iconStatus(item) {
    if (item.bindStatus === 'BIND') {
      if (item.cameraStatus === 'ONLINE') {
        return this.$style.green;
      } else {
        return this.$style.red;
      }
    } else {
      return this.$style.orange;
    }
  }
}
</script>
<style lang="less" module>
@fontColor: rgba(5, 211, 255, 1);
.videoSearch {
  height: 100%;
  padding: 0.2rem 0;
}

.formWrap {
  padding: 0.06rem 0.2rem 0 0.2rem;
  display: flex;
  .inputWrap {
    flex: 1;
    height: 0.3rem;
    display: flex;
    border: 0.01rem solid @fontColor;
    border-radius: 0.04rem;

    .formIcon {
      color: @fontColor;
      height: 0.3rem;
      line-height: 0.3rem;
      font-size: 0.16rem;
      margin-left: 0.1rem;
      margin-right: 0.08rem;
    }
    .formInput {
      flex: 1;
      background-color: transparent;
      border: none;
      font-size: 0.14rem;
      background-image: none;
      box-sizing: border-box;
      margin: 0;
      font-variant: tabular-nums;
      list-style: none;
      outline: none;
      color: #ffffff;

      &::-webkit-input-placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &::-ms-input-placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &::-moz-input-placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }
  .formSearch {
    cursor: pointer;
    margin-left: 0.1rem;
    width: 0.7rem;
    height: 0.3rem;
    font-size: 0.14rem;
    text-align: center;
    line-height: 0.3rem;
    border-radius: 0.04rem;
    border: 0.01rem solid @fontColor;
    color: @fontColor;
  }
}
.searchList {
  padding: 0 0.2rem;
  height: calc(100% - 0.36rem);
  overflow-y: auto;
}
.searchItem {
  border-top: 0.24rem solid transparent;
  color: #fff;
  .searchTitle {
    font-size: 0.14rem;
    cursor: pointer;
    .focusIcon {
      margin-right: 0.1rem;
    }
  }
  .searchVideoItem {
    margin-top: 0.08rem;
    padding: 0 0.2rem;
    position: relative;
    cursor: move;
    &.notMove {
      cursor: default;
    }
    .searchIcon {
      color: @fontColor;
      &.disabled {
        color: #f20;
      }
    }
    .name {
      font-size: 0.14rem;
      margin-left: 0.1rem;
    }
    .monitorIcon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      &.green {
        color: rgba(126, 236, 53, 1);
      }
      &.orange {
        color: rgba(236, 126, 53, 1);
      }
      &.red {
        color: rgba(212, 17, 17, 1);
      }
    }
  }
}
</style>
