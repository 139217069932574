<template>
  <div :class="$style.main">
    <div :class="$style.mainWrap">
      <div :class="[$style.leftMain]">
        <editor-view
          ref="refEditor"
          @delete="handleVideoDeleted"
          @list-change="handleListChange"
        />
      </div>
      <div :class="$style.rightMain">
        <list-view ref="refList" />
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import Draggable from 'vuedraggable';
import EditorView from './editor.vue';
import ListView from './list-view.vue';
@Component({
  components: {
    Draggable,
    EditorView,
    ListView,
  },
})
export default class MainView extends Vue {
  handleVideoDeleted(cameraId) {
    this.$refs.refList.handleDeleteModel(cameraId);
  }

  handleListChange(list) {
    this.$refs.refList.handleChangeModelList(list);
  }
}
</script>
<style lang="less" module>
@fontColor: rgba(5, 211, 255, 1);
.main {
  padding: 0.2rem;
  .mainWrap {
    display: flex;
    height: 100%;

    .leftMain {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
    }

    .rightMain {
      margin-left: 0.14rem;
      width: 3.6rem;
      height: 100%;
    }
  }
}
</style>
