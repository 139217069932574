<template>
  <edit-video-model />
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import EditVideoModel from './video-model.vue';
import { flexDpr } from '@/assets/js/flex-dpr.js';

flexDpr();

@Component({
  components: {
    EditVideoModel,
  },
})
export default class EditCameraView extends Vue {
  mounted() {}
}
</script>
